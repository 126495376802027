@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap');

html {
  scroll-behavior: smooth;
  margin: 0;
  font-family: 'Sarabun', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100vw);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-in-left {
  animation: slideInFromLeft 0.5s;
}


@keyframes slideInFromRight {
  0% {
    transform: translateX(100vw);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-in-right {
  animation: slideInFromRight 0.5s;
}


@keyframes slideInFromBottom {
  0% {
    transform: translateY(100vw);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.slide-in-bottom {
  animation: slideInFromBottom 0.25s;
}



@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
.fade-in {
  animation: fadeIn 0.25s ease-out forwards;
}

@layer utilities {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
    }
  }

.chalk-text {
  font-family: 'Nothing You Could Do', sans-serif; /* Apply chalk-like font */
}

